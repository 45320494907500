import { Button, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo/logo.png";
import { useDispatch } from "react-redux";
import { userExists } from "../redux/reducers/authSlice";

const Login = () => {
  const [cookies, setCookie] = useCookies();
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [otp, setOtp] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginHandler = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );
      const data = await response.json();
      if (!data?.success) {
        throw new Error(data.message);
      }

      // setCookie("token", data.token);
      toast({
        description: data.message,
        position: "top",
        status: "success",
      });
      setVerifyOtp(true);
      // navigate("/dashboard");
    } catch (error) {
      toast({
        description: error.message,
        position: "top",
        status: "error",
      });
    }
  };

  const verifyOtpHandler = async (e) => {
    e.preventDefault();

    if(!otp || otp.length < 6 || otp.length > 6){
      toast({
        description: 'OTP must be 6 digits long',
        position: "top",
        status: "error",
      });
      return;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "auth/verify-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            otp
          }),
        }
      );
      const data = await response.json();
      if (!data?.success) {
        throw new Error(data.message);
      }

      toast({
        description: data.message,
        position: "top",
        status: "success",
      });
      dispatch(userExists(data.user));
      setCookie("token", data.token);
      navigate("/dashboard");
    } catch (error) {
      toast({
        description: error.message,
        position: "top",
        status: "error",
      });
    }
  };

  const resendOtpHandler = async (e) => {
    e.preventDefault();

    if(!email){
      return;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "auth/resend-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email
          }),
        }
      );
      const data = await response.json();
      if (!data?.success) {
        throw new Error(data.message);
      }

      toast({
        description: data.message,
        position: "top",
        status: "success",
      });
    } catch (error) {
      toast({
        description: error.message,
        position: "top",
        status: "error",
      });
    }
  };

  const loginWithToken = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "auth/login-with-token",
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${cookies?.token}`,
          },
        }
      );
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      dispatch(userExists(data.user));
      navigate("/dashboard");
    } catch (error) {
      // toast({
      //   description: error.message,
      //   status: "error",
      //   position: "top",
      // });
      // navigate("/");
    }
  };

  useEffect(() => {
    if (cookies?.token) {
      loginWithToken();
    }
  }, []);

  return (
    <div className="relative w-[100%] h-[100vh] overflow-auto">
      <div className="px-3 py-2">
        <img className="w-[9rem] object-cover" src={logo} alt="logo" />
      </div>
      {!verifyOtp && <form
        onSubmit={loginHandler}
        className="w-[90%] md:w-[50%] absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 border px-10 py-10"
      >
        <h1 className="text-3xl font-bold mb-10">Login</h1>
        <FormControl isRequired={true}>
          <FormLabel>Email Id</FormLabel>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
        </FormControl>
        <FormControl className="mt-2" isRequired={true}>
          <FormLabel>Password</FormLabel>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </FormControl>
        <div className="mt-4">
          <Button type="submit" width={"100%"} colorScheme="blue">
            Login
          </Button>
        </div>
      </form>}
      {verifyOtp && <form
        onSubmit={verifyOtpHandler}
        className="w-[90%] md:w-[50%] absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 border px-10 py-10"
      >
        <h1 className="text-3xl font-bold mb-10">OTP Verification</h1>
        <FormControl isRequired={true}>
          <FormLabel>OTP</FormLabel>
          <Input
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            type="text"
          />
        </FormControl>
        <div className="mt-4 flex gap-2">
          <Button type="button" onClick={resendOtpHandler} width={"100%"} colorScheme="blue">
            Resend OTP
          </Button>
          <Button type="submit" width={"100%"} colorScheme="blue">
            Verify OTP
          </Button>
        </div>
      </form>}
    </div>
  );
};

export default Login;
