import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    _id: undefined,
    email: undefined,
    name: undefined,
    isSuper: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        userExists: (state, action)=>{
            console.log(action)
            state._id = action.payload._id;
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.isSuper = action.payload.isSuper;
        },
        userNotExists: (state)=>{
            state._id = undefined;
            state.email = undefined;
            state.name = undefined;
            state.isSuper = false;
        }
    }
});

export default authSlice;
export const {userExists, userNotExists} = authSlice.actions;